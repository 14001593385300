<template>
  <div>
    <Header activeTit="首页"></Header>
    <div class="banner">
      <img src="./../assets/img/banner.png" alt="">
    </div>
    <!-- 关于我们 -->
    <div class="about-us">
      <div class="left">
        <div class="tit">
          <img src="./../assets/img/关于我们.png" alt="">
          <span class="gray">关于我们</span>
        </div>
        <div class="bgtext">ABOUT US</div>
      </div>
      <div class="content">
        <div class="headline">公司简介</div>
        <div class="line"></div>
        <div class="text">北京帕斯泰克科技有限公司是一家集研发、生产和销售于一体的科技型公司，是专业的中医脉诊仪供应商和脉诊大数据服务商。公司总部位于北京市海淀区，生产基地位于天津市武清区，是北京市中关村高新技术企业。</div>
        <div class="text">公司拥有强大的科研力量，在获得中国中医科学院研究成果转化的基础上，与清华大学和天津中医药大学等国内顶级科研机构密切合作。</div>
        <div class="text">2016年10月，公司与天津中医药大学共建“创新中医联合实验室”，该实验室拥有津京高校科技创新园1100多平米场地，主要开展中医创新理论和应用研究，中医脉诊客观化研究与应用，中西医结合的医疗（中医诊疗）仪器的研发和应用以及中医健康管理工程的研究与应用等。</div>
        <div class="skip">
          <img src="./../assets/img/黑箭头.png" alt="">
          <span @click="changePage('/about')">查看详情</span>
        </div>
      </div>
      <div class="pic">
        <img src="./../assets/img/大厦.png" alt="">
        <div class="cover"></div>
      </div>
    </div>
    <!-- 产品中心 -->
    <div class="product-center">
      <div class="left-pink">
        <div class="white-box">
          <div class="english">We always make the <br/>best products</div>
          <div class="desc">我们始终做最好的产品</div>
        </div>
        <div class="detail detailL">
          <div class="pic-desc">
            <img src="./../assets/img/产品自定义.png" alt="">
            <div class="pic-tit">产品需求自定义</div>
            <div class="white-line"></div>
            <div class="pic-text">可根据不同的使用场景或检测对象设计软件操作流程及功能。</div>
          </div>
          <div class="pic-desc">
            <img src="./../assets/img/云数据.png" alt="" class="sImg">
            <div class="pic-tit">云端大数据库</div>
            <div class="white-line"></div>
            <div class="pic-text">可根据不同的使用场景或检测对象设计软件操作流程及功能。</div>
          </div>
        </div>
      </div>
      <div class="right-black">
        <div class="right-cont">
          <div class="white-box">
            <div class="right-desc">三探头中医智能脉诊仪融合了“脉诊客观化、互联网+中医、中医+大数据”等发展理念，打破了中医远程诊疗的唯一门槛，是中医诊疗与养生保健的标准化入口设备。</div>
            <div class="skip">
              <img src="./../assets/img/白箭头.png" alt="">
              <span @click="changePage('/product')">查看详情</span>
            </div>
          </div>
          <div class="detail">
            <div class="pic-desc">
              <img src="./../assets/img/检测报告.png" alt="" class="rImg">
              <div class="pic-tit">检测报告即时获得</div>
              <div class="white-line"></div>
              <div class="pic-text">可根据不同的使用场景或检测对象设计软件操作流程及功能。</div>
            </div>
            <div class="pic-desc">
              <img src="./../assets/img/研发力量.png" alt="">
              <div class="pic-tit">研发力量雄厚</div>
              <div class="white-line"></div>
              <div class="pic-text">可根据不同的使用场景或检测对象设计软件操作流程及功能。</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="tit">
            <div>产品中心</div>
            <img src="./../assets/img/产品中心.png" alt="">
          </div>
          <div class="bgtext">PRODUCT</div>
        </div>
      </div>
    </div>
    <!-- 新闻中心 -->
    <div class="about-us news-center">
      <div class="left">
        <div class="tit">
          <img src="./../assets/img/关于我们.png" alt="">
          <span class="gray">新闻中心</span>
        </div>
        <div class="bgtext">NEWS CENTER</div>
      </div>
      <div class="content content1">
        <div class="headline headline1">祝贺三探头中医脉诊仪荣获二类医疗器械注册证</div>
        <div class="line"></div>
        <div class="text">2020年7月6日，由帕斯泰克公司自主研发的三探头中医智能脉诊仪（MS3-IV）获得由天津市药品监督管理局颁发的二类医疗器械注册证。此次收到《医疗器械注册证》的产品名为三探头中医脉诊仪，主要用于对成年人群进行人体脉诊信息采集。此次三探头中医脉诊仪《医疗器械注册证》的取得，有助于满足市场多样化的中医脉诊检测需求，进一步提升公司的核心竞争力与综合服务能力。</div>
        <div class="skip">
          <!-- <img src="./../assets/img/黑箭头.png" alt="">
          <span>查看更多</span> -->
        </div>
        <div class="pic pic1 pic2">
          <img src="./../assets/img/新闻图片2.png" alt="">
          <div class="cover cover2">01 / 05</div>
        </div>
        <div class="skip more">
          <img src="./../assets/img/黑箭头.png" alt="">
          <span @click="changePage('/news')">查看更多</span>
        </div>
      </div>
      <div>
        <div class="pic pic1">
          <img src="./../assets/img/新闻图片1.png" alt="">
          <div class="cover">01 / 05</div>
        </div>
        <div class="content content1 content2">
          <div class="headline headline1">帕斯泰克“2017国际创新创业博览会”圆满收官</div>
          <div class="line"></div>
          <div class="text text1">作为中国最具影响力的展会之一，本届国际创新创业博览会于2017年12月8日至10日在北京国家会议中心成功举办，展会共邀请了美国、俄罗斯、英国等20多个国家和地区的创新创业企业前来参展。帕斯泰克“三探头中医智能脉诊仪”作为年度高新技术产品之一，也受邀在本次“TOP 新技术、新产品”主题展馆中展出，并以其成功模拟中医把脉，实现中医脉诊客观化的体验效果轰动馆内，现代化的便携设计、互联网大数据与传统中医把脉相结合，让来场参观的近万名参观者和体验者为之赞叹！</div>
          <div class="skip skip1">
            <!-- <img src="./../assets/img/黑箭头.png" alt="">
            <span>查看更多</span> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 视频 -->
    <div class="video">
      企业视觉大片敬请期待
    </div>
    <!-- 产品中心 -->
    <div class="product-carousel">
      <div class="product">
        <div class="p_box" v-for="(v, k) in images" :key="k" @click="changePage('/product')">
          <img class="p_img" :src="require('./../assets/img/' + v.src)" alt="">
          <div class="p_tit">{{v.title}}</div>
        </div>
      </div>
      <div class="right">
        <div class="tit">
          <div class="gray">产品展示</div>
          <img src="./../assets/img/产品展示.png" alt="">
        </div>
        <div class="bgtext">SHOW</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './layout/Header'
import Footer from './layout/Footer'
export default {
  name: 'homepage',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      images: [
        { src: '脉诊仪.jpg', title: '医疗版脉诊仪' },
        { src: '社区版.jpg', title: '社区版脉诊仪' },
        { src: '四诊仪.png', title: '四诊仪' }
      ]
    }
  },
  methods: {
    changePage (data) {
      this.$router.push(data)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import './../assets/css/basic.styl'
.banner
  & img
    width 100%
    height 5.58rem
.about-us
  height 8.73rem
  display flex
  & .left
    padding-top 1.87rem
    width 2.45rem
    .tit
      text-align right
      border-bottom 0.02rem solid #cecece
      padding-bottom 0.15rem
      & img
        margin-right 0.13rem
        width 0.4rem
      & .gray
        font-size $fontMid
        color #333
        vertical-align bottom
    .bgtext
      font-size 0.8rem
      @extend .bold
      transform rotate(90deg)
      transform-origin left top
      margin-left 2.6rem
      margin-top 0.6rem
      white-space nowrap
      color #f5f5f5
  & .content
    width 5.9rem
    margin-left 1.16rem
    margin-right 1.42rem
    padding-top 0.8rem
    .headline
      font-size $fontMid
      @extend .bold
    .line
      margin 0.26rem auto 0.18rem 0
      width 0.28rem
      height 0.05rem
      background $pink
    .text
      line-height 0.35rem
      text-indent 2em
      margin-bottom 0.5rem
      font-size $fontSmall
    .skip
      margin-top 0.8rem
      & img
        margin-right 0.17rem
        width 0.4rem
      & span
        font-size $fontSmall
        @extend .bold
        color $pink
        cursor pointer
  & .pic
    margin-top 1.6rem
    width 4.5rem
    height 5.2rem
    border 0.05rem solid #dcdcdc
    position relative
    & img
      width 4.5rem
      height 5.2rem
      transform translate(0.2rem, -0.3rem)
    .cover
      position absolute
      left 0
      bottom 0
      width 0.65rem
      height 2.05rem
      background #ffffff
      z-index 100
.product-center
  border 1px solid
  height 8.71rem
  display flex
  color #fff
  .left-pink
    width 50%
    background url('./../assets/img/productbg.png')
    background-size 9.6rem 8.71rem
    padding-top 0.9rem
    .white-box
      border 0.05rem solid #fff
      border-right none
      margin-left 3.6rem
      padding 0.5rem 0 0.74rem 0.35rem
      height 3.3rem
      .english
        font-size 0.55rem
        line-height 0.65rem
        margin-bottom 0.3rem
      .desc
        font-size $fontMid
    .detailL
      padding-left 3.6rem
  .right-black
    width 50%
    background #000
    padding-top 0.9rem
    display flex
    justify-content space-between
    .right-cont
      width 6rem
      .white-box
        height 3.3rem
        border 0.05rem solid #fff
        border-left none
        padding 0.69rem 0 0.45rem 0.17rem
        font-size $fontSmall
        @extend .bold
        .right-desc
          line-height 0.4rem
          margin-bottom 0.65rem
        .skip
          & img
            margin-right 0.17rem
            width 0.4rem
          & span
            cursor pointer
    .right
      padding-top 1.15rem
      width 2.1rem
      .tit
        border-bottom 0.02rem solid #fff
        padding-bottom 0.15rem
        font-size $fontMid
        display flex
        & div
          line-height 0.3rem
        & img
          width 0.3rem
          margin-left 0.13rem
      .bgtext
        font-size 0.8rem
        @extend .bold
        transform rotate(90deg)
        transform-origin left top
        margin-left 0.7rem
        margin-top 0.6rem
        color #545454
  & .detail
    display flex
    padding-top 0.8rem
    justify-content space-around
    .pic-desc
      width 2.45rem
      text-align center
      & img
        width 0.72rem
        height 0.72rem
      .sImg
        width 0.9rem
      .rImg
        width 0.6rem
      .pic-tit
        font-size 0.23rem
        margin 0.24rem auto
        @extend .bold
      .white-line
        width 0.35rem
        height 0.03rem
        background #fff
        margin 0 auto 0.3rem
      .pic-text
        line-height 0.3rem
        @extend .bold
.news-center
  height 11.92rem
  .content1
    width 5.8rem
    margin-right 0.55rem
    .headline1
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
    .text
      height 2.4rem
      overflow hidden
      text-overflow ellipsis
      display -webkit-box
      -webkit-box-orient vertical
      -webkit-line-clamp 7
    .more
      margin-top 0.66rem
      width 1.71rem
      height 0.5rem
      line-height 0.5rem
      border 0.02rem solid #dcdcdc
      text-align left
      padding-left 0.08rem
  .pic1
    margin-top 1rem
    width 5.4rem
    height 4.08rem
    & img
      width 5.4rem
      height 4.08rem
      transform translate(0.15rem, -0.25rem)
    .cover
      position absolute
      left 0.13rem
      bottom 0.14rem
      width 0.85rem
      height 0.37rem
      line-height 0.37rem
      background #ffffff
      z-index 100
      text-align center
      font-size $fontSmall
  .content2
    margin-left 0
  .pic2
    margin-left 0.2rem
    margin-top 1.8rem
    & img
      transform translate(-0.2rem, -0.25rem)
    .cover2
      position absolute
      left 4.35rem
      bottom 0.14rem
.video
  height 6.48rem
  background #000
  color #fff
  font-size $fontMid
.product-carousel
  height 6.3rem
  padding-top 0.82rem
  padding-bottom 1.1rem
  display flex
  .product
    width 11.25rem
    margin 0 auto
    display flex
    .p_box
      height 4rem
      margin 0.5rem
      .p_img
        width 3rem
        height 3.4rem
      .p_tit
        font-size 0.28rem
        text-align center
        line-height 0.7rem
        margin-top -0.1rem
    .p_box:hover
      color #004ea2
      cursor pointer
      .p_img
        border 0.01rem solid #004ea2
      .p_tit
        background #004ea2
        color #fff
  .right
    padding-top 0.61rem
    width 2.34rem
    .tit
      border-bottom 0.02rem solid #cecece
      padding-bottom 0.15rem
      font-size $fontMid
      display flex
      & .gray
        font-size $fontMid
        color #333
        vertical-align bottom
        line-height 0.3rem
        margin-right 0.43rem
      & img
        width 0.3rem
    .bgtext
      font-size 0.8rem
      @extend .bold
      transform rotate(90deg)
      transform-origin left top
      margin-left 0.75rem
      margin-top 0.6rem
      color #f5f5f5
</style>
